import type { ApiENV as ENV } from '@koolumbus/shared/config';

export const isTestEmail = (email: string) => {
  return (
    email.endsWith('@test.myothispms.com') ||
    email.endsWith('@test.myothis.com') ||
    email.endsWith('@test.koolumbus.com') ||
    email.endsWith('@test.com')
  );
};

export const shid = () => {
  return Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8);
};

export function getPMSDomain(env: ENV, tenantSubdomain?: string | null): string {
  if (env === 'development') {
    return 'http://localhost:4201';
  }

  if (env === 'staging') {
    return `https://${process.env.RENDER_EXTERNAL_HOSTNAME!}`;
  }

  if (tenantSubdomain) {
    return `https://${tenantSubdomain}.koolumbus.com`;
  }

  return 'https://admin.koolumbus.com';
}

export function getPortalDomain(env: ENV): string {
  if (env === 'development') {
    return 'http://localhost:4200';
  }

  if (env === 'staging') {
    return `https://${process.env.RENDER_EXTERNAL_HOSTNAME!}`;
  }

  return 'https://koolumbus.com';
}

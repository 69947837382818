import { Locale } from '@koolumbus/shared/config';

export const formatAdults = (value: number, locale: Locale) => {
  switch (locale) {
    case 'it':
      return `${value} adult${value === 1 ? 'o' : 'i'}`;
    case 'en':
    default:
      return `${value} adult${value > 1 ? 's' : ''}`;
  }
};

export const formatChildren = (value: number, locale: Locale) => {
  switch (locale) {
    case 'it':
      return `${value} bambin${value === 1 ? 'o' : 'i'}`;
    case 'en':
    default:
      return `${value} child${value === 1 ? '' : 'ren'}`;
  }
};

export const formatGuests = (adults: number, children: number, locale: Locale) => {
  return `${formatAdults(adults, locale)}${
    children > 0 ? `, ${formatChildren(children, locale)}` : ''
  }`;
};

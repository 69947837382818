import { User, UserRole } from '@prisma/client';

export const allRoles: UserRole[] = Object.values(UserRole);

export const isRole = (value: unknown): value is UserRole => {
  return typeof value === 'string' && allRoles.includes(value as UserRole);
};

export const internalRoles: UserRole[] = ['ADMIN', 'MANAGER'];
export const externalRoles: UserRole[] = Object.keys(UserRole).filter(
  (role) => !internalRoles.includes(role as UserRole),
) as UserRole[];

export const allRolesExcept = (...excluded: UserRole[]) => {
  return allRoles.filter((role) => !excluded.includes(role));
};

export const isAdmin = (user: User | undefined): boolean => {
  return user?.role === 'ADMIN';
};

// returns true if the user has at least one of the [roles]
export function hasRoles(user: User | undefined, ...roles: UserRole[]): boolean {
  if (!user) return false;

  for (const role of roles) {
    if (user.role === role) {
      return true;
    }
  }

  return false;
}

export const hasInternalRole = (user: User | undefined): boolean => {
  return typeof user !== 'undefined' && user.role !== null && internalRoles.includes(user.role);
};

export const hasExternalRole = (user: User | undefined): boolean => {
  return !hasInternalRole(user);
};

// web utils

export const rolesLabels: { [key in UserRole]: string } = {
  ADMIN: 'Admin',
  MANAGER: 'Manager',
  OWNER: 'Owner',
  RECEIVER: 'Receiver',
  OPERATOR: 'Operator',
};

export const getRoleLabel = (role: UserRole): string => rolesLabels[role];

import { WebENV } from '@koolumbus/shared/config';

export function getApiTrpcUrl(env: WebENV, isServer = false): string {
  const host = getApiHost(env, isServer);
  return `${host}/trpc`;
}

function getApiHost(env: WebENV, isServer = false): string {
  if (!isServer) {
    return '/api';
  }

  if (env === 'development') {
    return 'http://localhost:4200/api';
  }

  return `https://${process.env.RENDER_EXTERNAL_HOSTNAME!}/api`;
}

import { Dispatch, SetStateAction, useState } from 'react';

export const useToggle = (
  initialValue = false,
): [
  boolean,
  {
    toggle: () => void;
    on: () => void;
    off: () => void;
    set: Dispatch<SetStateAction<boolean>>;
  },
] => {
  const [value, setValue] = useState(initialValue);

  return [
    value,
    {
      toggle: () => setValue((v) => !v),
      on: () => setValue(true),
      off: () => setValue(false),
      set: setValue,
    },
  ];
};

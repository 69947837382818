import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring';
import { useEffect, useMemo, useState } from 'react';

// common defs

export const numParam: ParamDef<number> = {
  encode: (value) => value.toString(),
  decode: (value) => (typeof value === 'string' && value ? parseInt(value, 10) : undefined),
};

export const boolParam: ParamDef<boolean> = {
  get: (value) => value ?? undefined,
  set: (value) => value || undefined,

  encode: (value) => (value ? '1' : undefined),
  decode: (value) => value === '1',
};

export const tripleBoolParam: ParamDef<boolean> = {
  encode: (value) => (value ? '1' : '0'),
  decode: (value) => {
    if (typeof value !== 'string') {
      return;
    }

    if (value === '1') {
      return true;
    }

    if (value === '0') {
      return false;
    }

    return undefined;
  },
};

export function dateParam(): ParamDef<Date> {
  return {
    encode: (value) => (value ? dayjs(value).format('YYYY-MM-DD') : undefined),
    decode: (value) => (typeof value === 'string' ? new Date(value) : undefined),
  };
}

export const datesParam: ParamDef<[Date, Date]> = {
  encode: (value) => value.map((date) => date.toISOString()),
  decode: (value) => {
    if (Array.isArray(value) && value.length === 2) {
      return value.map((date) => new Date(date)) as [Date, Date];
    }

    return undefined;
  },
};

export function jsonParam<T>(): ParamDef<T> {
  return {
    encode: (value) => (value ? JSON.stringify(value) : undefined),
    decode: (value) => (typeof value === 'string' ? JSON.parse(value) : undefined),
  };
}

export interface ParamDef<V> {
  get?: (value: V | undefined) => V | undefined;
  set?: (value: V | undefined) => V | undefined;

  encode: (value: V) => ParsedUrlQueryInput[string];
  decode: (value: ParsedUrlQuery[string]) => V | undefined;
}

export const useSearchParams = <V extends Record<string, unknown>>(defs: {
  [K in keyof V]: ParamDef<V[K]>;
}): { params: Partial<V>; setParams: (value: Partial<V>) => void; resetParams: () => void } => {
  const router = useRouter();

  const [_params, _setParams] = useState<Partial<V>>(() => {
    const params: V = {} as V;

    for (const [key, def] of Object.entries(defs)) {
      const value = router.query[key];

      if (typeof value !== 'undefined') {
        params[key as keyof V] = def.decode(value);
      }
    }

    return params;
  });

  useEffect(() => {
    const query: ParsedUrlQueryInput = {
      ...router.query,
    };

    for (const [key, def] of Object.entries(defs)) {
      const value = _params[key as keyof V];

      if (typeof value === 'undefined') {
        delete query[key];
      } else {
        query[key] = def.encode(value);
      }
    }

    router.replace({ query }, { query }, { shallow: true, scroll: false });
  }, [_params]);

  const params = useMemo(() => {
    const params: V = {} as V;

    for (const [key, def] of Object.entries(defs)) {
      const value = _params[key as keyof V];
      params[key as keyof V] = typeof def.get !== 'undefined' ? def.get(value) : value;
    }

    return params;
  }, [_params]);

  const setParams = (value: Partial<V>) => {
    const newValue: Partial<V> = {
      ..._params,
    };

    for (const [key, val] of Object.entries(value)) {
      const k = key as keyof V;

      if (typeof defs[k].set !== 'undefined') {
        newValue[k] = defs[k].set!(val);
      } else {
        newValue[k] = val;
      }
    }

    _setParams({ ...newValue });
  };

  const resetParams = () => {
    _setParams({});
  };

  return { params, setParams, resetParams };
};

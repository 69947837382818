import { ChatMessage } from '@koolumbus/shared/data';
import type { DocumentSnapshot, QueryDocumentSnapshot } from 'firebase/firestore';

export const docToChatMessage = (
  doc: DocumentSnapshot<unknown> | QueryDocumentSnapshot<unknown>,
): ChatMessage => {
  return {
    ...(doc.data() as Record<string, unknown>),
    id: doc.id,
  } as ChatMessage;
};

import { FacilityKey } from '@koolumbus/shared/utils';
import { MAX_AUCTION_PRICE_TO, MIN_AUCTION_PRICE_FROM } from '@koolumbus/web/utils';
import { Location } from '@prisma/client';
import { useEffect } from 'react';
import createStore from 'zustand';
import { isDateString } from '../helpers/date';
import { SearchSortBy } from '../models';

export type DatesRange = {
  dateFrom: Date;
  dateTo: Date;
};

export type BudgetValue = {
  priceFrom: number | null;
  priceTo: number | null;
};

export type GuestsValue = {
  adults: number;
  children: number;
};

export type RoomsValue = {
  bedrooms: number;
  restrooms: number;
};

export interface SearchState {
  location?: Location;
  budget: BudgetValue;
  dates?: DatesRange;
  guests: GuestsValue;
  rooms: RoomsValue;
  furnished: boolean;
  minSquareMeters: number;
  amenities: FacilityKey[];
}

type SearchStateStoreMeta = {
  homeFormType: 'search' | 'auction';
  auctionFormInitialStep: number;
  auctionNotes: string;
  searchSortBy: SearchSortBy | null;
};

type SearchStateStore = {
  /** Indicates if state restoration from local storage has finished */
  ready: boolean;
  state: SearchState;
  meta: SearchStateStoreMeta;
  setState: (value: Partial<SearchState>, ready?: boolean) => void;
  resetState: () => void;
  setMeta: (value: Partial<SearchStateStoreMeta>) => void;
  setReady: () => void;
};

const defaultSearchState: SearchState = {
  budget: {
    priceFrom: null,
    priceTo: null,
  },
  furnished: true,
  guests: {
    adults: 1,
    children: 0,
  },
  rooms: {
    bedrooms: 0,
    restrooms: 1,
  },
  minSquareMeters: 0,
  amenities: [],
};

export const useSearchState = createStore<SearchStateStore>((set) => ({
  ready: false,
  state: defaultSearchState,
  meta: {
    homeFormType: 'search',
    auctionFormInitialStep: 0,
    auctionNotes: '',
    searchSortBy: null,
  },
  setState: (value, ready = false) => {
    return set((oldState) => {
      const { budget, ...newData } = {
        ...oldState.state,
        ...value,
      };

      const { priceFrom, priceTo } = budget;

      return {
        ready,
        state: {
          ...newData,
          budget: {
            priceFrom: priceFrom === MIN_AUCTION_PRICE_FROM ? null : priceFrom,
            priceTo: priceTo === MAX_AUCTION_PRICE_TO ? null : priceTo,
          },
        },
      };
    });
  },
  resetState: () => set({ state: defaultSearchState }),
  setMeta: (value) => {
    return set((oldState) => {
      return {
        meta: {
          ...oldState.meta,
          ...value,
        },
      };
    });
  },
  setReady: () => set({ ready: true }),
}));

const searchStateStorageKey = 'koolumbus.search-state';

const dateReviver = (_: string, value: unknown): unknown =>
  typeof value === 'string' && value && isDateString(value) ? new Date(value) : value;

export const useSearchStateStorage = () => {
  const store = useSearchState();

  useEffect(() => {
    const searchState = window.localStorage.getItem(searchStateStorageKey);

    if (searchState !== null) {
      const value = JSON.parse(searchState, dateReviver) as Pick<
        SearchStateStore,
        'state' | 'meta'
      >;
      store.setState(value.state, true);
      store.setMeta(value.meta);
    } else {
      store.setReady();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.localStorage.setItem(
      searchStateStorageKey,
      JSON.stringify({ state: store.state, meta: store.meta }),
    );
  }, [store.meta, store.state]);
};

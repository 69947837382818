import { config } from '@koolumbus/web/config';
import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  enabled: config.env !== 'development',
  environment: config.env,
  dsn: SENTRY_DSN,
});

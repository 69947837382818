import { Locale } from '@koolumbus/shared/config';
import { Location } from '@prisma/client';

// countries

type LocationCountry = 'ITALY' | 'SPAIN' | 'OTHER';

type LocationCountryDetails = {
  name: Record<Locale, string> | string;
};

const locationsCountriesDetails: Record<LocationCountry, LocationCountryDetails> = {
  ITALY: { name: { en: 'Italy', it: 'Italia' } },
  SPAIN: { name: { en: 'Spain', it: 'Spagna' } },
  OTHER: { name: { en: 'Other', it: 'Altro' } },
};

export const locationCountries = Object.keys(locationsCountriesDetails) as LocationCountry[];

export const getLocationCountryName = (country: LocationCountry, locale: Locale = 'en') => {
  const value = locationsCountriesDetails[country].name;

  if (typeof value === 'string') {
    return value;
  }

  return value[locale];
};

// regions

type LocationGroup =
  | 'LOMBARDY'
  | 'LAZIO'
  | 'TUSCANY'
  | 'SICILY'
  | 'SARDINIA'
  | 'APULIA'
  | 'CALABRIA'
  | 'EMILIA'
  | 'AOSTA'
  | 'VENETO'
  | 'SPAIN'
  | 'OTHERS';

type LocationGroupDetails = {
  name: Record<Locale, string> | string;
};

const locationsGroupsDetails: Record<LocationGroup, LocationGroupDetails> = {
  CALABRIA: { name: 'Calabria' },
  EMILIA: { name: 'Emilia-Romagna' },
  LAZIO: { name: 'Lazio' },
  LOMBARDY: { name: { en: 'Lombardy', it: 'Lombardia' } },
  APULIA: { name: { en: 'Apulia', it: 'Puglia' } },
  SARDINIA: { name: { en: 'Sardinia', it: 'Sardegna' } },
  SICILY: { name: { en: 'Sicily', it: 'Sicilia' } },
  TUSCANY: { name: { en: 'Tuscany', it: 'Toscana' } },
  VENETO: { name: 'Veneto' },
  AOSTA: { name: { en: 'Aosta Valley', it: "Val d'Aosta" } },
  SPAIN: { name: { en: 'Spain', it: 'Spagna' } },
  OTHERS: { name: { it: 'Altre', en: 'Others' } },
};

export const locationsGroups = Object.keys(locationsGroupsDetails) as LocationGroup[];

export const getLocationGroupName = (group: LocationGroup, locale: Locale = 'en') => {
  const value = locationsGroupsDetails[group].name;

  if (typeof value === 'string') {
    return value;
  }

  return value[locale];
};

// locations

type LocationDetails = {
  name: Record<Locale, string> | string;
  group: LocationGroup;
  country: LocationCountry;
  center: { lat: number; lng: number };
  active?: boolean;
  top?: true;
};

export const locationsDetails: Record<Location, LocationDetails> = {
  // Lombardia
  BERGAMO: {
    name: 'Bergamo',
    group: 'LOMBARDY',
    country: 'ITALY',
    center: { lat: 45.695, lng: 9.67 },
  },
  BRESCIA: {
    name: 'Brescia',
    group: 'LOMBARDY',
    country: 'ITALY',
    center: { lat: 45.5389, lng: 10.2203 },
  },
  COMO: {
    name: 'Como',
    group: 'LOMBARDY',
    country: 'ITALY',
    active: true,
    center: { lat: 45.8103, lng: 9.0861 },
  },
  CREMONA: {
    name: 'Cremona',
    group: 'LOMBARDY',
    country: 'ITALY',
    center: { lat: 45.1167, lng: 10.0333 },
  },
  LECCO: {
    name: 'Lecco',
    group: 'LOMBARDY',
    country: 'ITALY',
    center: { lat: 45.85, lng: 9.3833 },
  },
  LODI: {
    name: 'Lodi',
    group: 'LOMBARDY',
    country: 'ITALY',
    center: { lat: 45.3167, lng: 9.5 },
  },
  MANTUA: {
    name: { it: 'Mantova', en: 'Mantua' },
    group: 'LOMBARDY',
    country: 'ITALY',
    center: { lat: 45.15, lng: 10.8 },
  },
  MILAN: {
    name: { en: 'Milan', it: 'Milano' },
    group: 'LOMBARDY',
    country: 'ITALY',
    center: { lat: 45.4669, lng: 9.19 },
    active: true,
    top: true,
  },
  MONZA: {
    name: 'Monza',
    group: 'LOMBARDY',
    country: 'ITALY',
    center: { lat: 45.5836, lng: 9.2736 },
  },
  PAVIA: {
    name: 'Pavia',
    group: 'LOMBARDY',
    country: 'ITALY',
    center: { lat: 45.1667, lng: 9.1667 },
  },
  SONDRIO: {
    name: 'Sondrio',
    group: 'LOMBARDY',
    country: 'ITALY',
    center: { lat: 46.1667, lng: 9.8667 },
  },
  VARESE: {
    name: 'Varese',
    group: 'LOMBARDY',
    country: 'ITALY',
    center: { lat: 45.8167, lng: 8.8333 },
  },

  // Sardegna
  CAGLIARI: {
    name: 'Cagliari',
    group: 'SARDINIA',
    country: 'ITALY',
    center: { lat: 39.2224, lng: 9.104 },
  },
  NUORO: {
    name: 'Nuoro',
    group: 'SARDINIA',
    country: 'ITALY',
    center: { lat: 40.3167, lng: 9.35 },
  },
  ORISTANO: {
    name: 'Oristano',
    group: 'SARDINIA',
    country: 'ITALY',
    center: { lat: 39.9031, lng: 8.5919 },
  },
  SASSARI: {
    name: 'Sassari',
    group: 'SARDINIA',
    country: 'ITALY',
    center: { lat: 40.7267, lng: 8.5592 },
  },

  // Toscana
  AREZZO: {
    name: 'Arezzo',
    group: 'TUSCANY',
    country: 'ITALY',
    center: { lat: 43.4617, lng: 11.875 },
  },
  FLORENCE: {
    name: { en: 'Florence', it: 'Firenze' },
    group: 'TUSCANY',
    country: 'ITALY',
    center: {
      lat: 43.7714,
      lng: 11.2542,
    },
  },
  GROSSETO: {
    name: 'Grosseto',
    group: 'TUSCANY',
    country: 'ITALY',
    center: { lat: 42.7667, lng: 11.1333 },
  },
  LIVORNO: {
    name: 'Livorno',
    group: 'TUSCANY',
    country: 'ITALY',
    center: {
      lat: 43.55,
      lng: 10.3167,
    },
  },
  LUCCA: {
    name: 'Lucca',
    group: 'TUSCANY',
    country: 'ITALY',
    center: { lat: 43.8333, lng: 10.4833 },
  },
  MASSA_CARRARA: {
    name: 'Massa Carrara',
    group: 'TUSCANY',
    country: 'ITALY',
    center: { lat: 44.0167, lng: 10.15 },
  },
  PISA: {
    name: 'Pisa',
    group: 'TUSCANY',
    country: 'ITALY',
    center: {
      lat: 43.7167,
      lng: 10.4,
    },
  },
  PISTOIA: {
    name: 'Pistoia',
    group: 'TUSCANY',
    country: 'ITALY',
    center: {
      lat: 43.9167,
      lng: 10.9,
    },
  },
  PRATO: {
    name: 'Prato',
    group: 'TUSCANY',
    country: 'ITALY',
    center: {
      lat: 43.8808,
      lng: 11.0966,
    },
  },
  SIENA: {
    name: 'Siena',
    group: 'TUSCANY',
    country: 'ITALY',
    center: {
      lat: 43.3183,
      lng: 11.3314,
    },
  },

  // Lazio
  FROSINONE: {
    name: 'Frosinone',
    group: 'LAZIO',
    country: 'ITALY',
    center: {
      lat: 41.6333,
      lng: 13.3167,
    },
  },
  LATINA: {
    name: 'Latina',
    group: 'LAZIO',
    country: 'ITALY',
    center: {
      lat: 41.4676,
      lng: 12.9037,
    },
  },
  RIETI: {
    name: 'Rieti',
    group: 'LAZIO',
    country: 'ITALY',
    center: { lat: 42.4, lng: 12.85 },
  },
  ROME: {
    name: { en: 'Rome', it: 'Roma' },
    group: 'LAZIO',
    country: 'ITALY',
    center: {
      lat: 41.8931,
      lng: 12.4828,
    },
    active: true,
    top: true,
  },
  VITERBO: {
    name: 'Viterbo',
    group: 'LAZIO',
    country: 'ITALY',
    center: {
      lat: 42.4167,
      lng: 12.1,
    },
  },

  // Emilia
  BOLOGNA: {
    name: 'Bologna',
    group: 'EMILIA',
    country: 'ITALY',
    center: {
      lat: 44.4939,
      lng: 11.3428,
    },
    active: true,
  },
  FERRARA: {
    name: 'Ferrara',
    group: 'EMILIA',
    country: 'ITALY',
    center: {
      lat: 44.8353,
      lng: 11.6199,
    },
  },
  FORLI_CESENA: {
    name: 'Forlì Cesena',
    group: 'EMILIA',
    country: 'ITALY',
    center: {
      lat: 44.2225,
      lng: 12.0408,
    },
  },
  MODENA: {
    name: 'Modena',
    group: 'EMILIA',
    country: 'ITALY',
    center: {
      lat: 44.6458,
      lng: 10.9257,
    },
  },
  PARMA: {
    name: 'Parma',
    group: 'EMILIA',
    country: 'ITALY',
    center: {
      lat: 44.8015,
      lng: 10.328,
    },
  },
  PIACENZA: {
    name: 'Piacenza',
    group: 'EMILIA',
    country: 'ITALY',
    center: {
      lat: 45.05,
      lng: 9.7,
    },
  },
  RAVENNA: {
    name: 'Ravenna',
    group: 'EMILIA',
    country: 'ITALY',
    center: {
      lat: 44.4178,
      lng: 12.1994,
    },
  },
  REGGIO_EMILIA: {
    name: 'Reggio Emilia',
    group: 'EMILIA',
    country: 'ITALY',
    center: {
      lat: 44.7,
      lng: 10.6333,
    },
  },
  RIMINI: {
    name: 'Rimini',
    group: 'EMILIA',
    country: 'ITALY',
    center: {
      lat: 44.0594,
      lng: 12.5683,
    },
  },

  // Aosta
  AOSTA: {
    name: 'Aosta',
    group: 'AOSTA',
    country: 'ITALY',
    active: true,
    center: {
      lat: 45.737,
      lng: 7.315,
    },
  },

  // Sicilia
  AGRIGENTO: {
    name: 'Agrigento',
    group: 'SICILY',
    country: 'ITALY',
    center: {
      lat: 37.3111,
      lng: 13.5765,
    },
  },
  CALTANISSETTA: {
    name: 'Caltanissetta',
    group: 'SICILY',
    country: 'ITALY',
    center: { lat: 37.4833, lng: 14.0667 },
  },
  CATANIA: {
    name: 'Catania',
    group: 'SICILY',
    country: 'ITALY',
    active: true,
    center: {
      lat: 37.5027,
      lng: 15.0873,
    },
  },
  ENNA: {
    name: 'Enna',
    group: 'SICILY',
    country: 'ITALY',
    center: {
      lat: 37.5667,
      lng: 14.2667,
    },
  },
  MESSINA: {
    name: 'Messina',
    group: 'SICILY',
    country: 'ITALY',
    center: {
      lat: 38.1833,
      lng: 15.55,
    },
  },
  PALERMO: {
    name: 'Palermo',
    group: 'SICILY',
    country: 'ITALY',
    center: {
      lat: 38.1157,
      lng: 13.3613,
    },
  },
  RAGUSA: {
    name: 'Ragusa',
    group: 'SICILY',
    country: 'ITALY',
    center: {
      lat: 36.93,
      lng: 14.73,
    },
  },
  SYRACUSE: {
    name: { en: 'Syracuse', it: 'Siracusa' },
    group: 'SICILY',
    country: 'ITALY',
    center: {
      lat: 37.0692,
      lng: 15.2875,
    },
  },

  // Puglia
  TRAPANI: {
    name: 'Trapani',
    group: 'APULIA',
    country: 'ITALY',
    center: { lat: 38.0167, lng: 12.4833 },
  },
  BARI: {
    name: 'Bari',
    group: 'APULIA',
    country: 'ITALY',
    center: {
      lat: 41.1253,
      lng: 16.8667,
    },
  },
  BARLETTA_ANDRIA_TRANI: {
    name: 'Barletta-Andria-Trani',
    group: 'APULIA',
    country: 'ITALY',
    center: {
      lat: 41.32,
      lng: 16.27,
    },
  },
  BRINDISI: {
    name: 'Brindisi',
    group: 'APULIA',
    country: 'ITALY',
    center: {
      lat: 40.6383,
      lng: 17.9458,
    },
  },
  FOGGIA: {
    name: 'Foggia',
    group: 'APULIA',
    country: 'ITALY',
    center: {
      lat: 41.4584,
      lng: 15.5519,
    },
  },
  LECCE: {
    name: 'Lecce',
    group: 'APULIA',
    country: 'ITALY',
    center: {
      lat: 40.352,
      lng: 18.1691,
    },
  },
  TARANTO: {
    name: 'Taranto',
    group: 'APULIA',
    country: 'ITALY',
    center: {
      lat: 40.4181,
      lng: 17.2408,
    },
  },

  // Calabria
  CATANZARO: {
    name: 'Catanzaro',
    group: 'CALABRIA',
    country: 'ITALY',
    center: {
      lat: 38.91,
      lng: 16.5875,
    },
  },
  COSENZA: {
    name: 'Cosenza',
    group: 'CALABRIA',
    country: 'ITALY',
    center: {
      lat: 39.3,
      lng: 16.25,
    },
  },
  CROTONE: {
    name: 'Crotone',
    group: 'CALABRIA',
    country: 'ITALY',
    center: {
      lat: 39.0833,
      lng: 17.1233,
    },
  },
  REGGIO_CALABRIA: {
    name: 'Reggio Calabria',
    group: 'CALABRIA',
    country: 'ITALY',
    center: {
      lat: 38.1144,
      lng: 15.65,
    },
  },
  VIBO_VALENTIA: {
    name: 'Vibo Valentia',
    group: 'CALABRIA',
    country: 'ITALY',
    center: { lat: 38.6666, lng: 16.1 },
  },

  // Veneto
  BELLUNO: {
    name: 'Belluno',
    group: 'VENETO',
    country: 'ITALY',
    active: true,
    center: { lat: 46.145, lng: 12.2214 },
  },
  PADUA: {
    name: { en: 'Padua', it: 'Padova' },
    group: 'VENETO',
    country: 'ITALY',
    center: {
      lat: 45.4064,
      lng: 11.8778,
    },
  },
  ROVIGO: {
    name: 'Rovigo',
    group: 'VENETO',
    country: 'ITALY',
    center: { lat: 45.0667, lng: 11.7833 },
  },
  TREVISO: {
    name: 'Treviso',
    group: 'VENETO',
    country: 'ITALY',
    center: { lat: 45.6722, lng: 12.2422 },
  },
  VENICE: {
    name: { it: 'Venezia', en: 'Venice' },
    group: 'VENETO',
    country: 'ITALY',
    center: {
      lat: 45.4397,
      lng: 12.3319,
    },
  },
  VERONA: {
    name: 'Verone',
    group: 'VENETO',
    country: 'ITALY',
    center: {
      lat: 45.4386,
      lng: 10.9928,
    },
  },
  VICENZA: {
    name: 'Vicenza',
    group: 'VENETO',
    country: 'ITALY',
    center: { lat: 45.55, lng: 11.55 },
  },

  // Spain
  BARCELONA: {
    name: { en: 'Barcelona', it: 'Barcellona' },
    group: 'SPAIN',
    country: 'SPAIN',
    center: { lat: 41.390205, lng: 2.154007 },
    active: true,
    top: true,
  },
  TENERIFE: {
    name: 'Tenerife',
    group: 'SPAIN',
    country: 'SPAIN',
    center: { lat: 28.291565, lng: -16.629129 },
    active: true,
    top: true,
  },
  // Others
  CUBA: {
    name: 'Cuba',
    group: 'OTHERS',
    country: 'OTHER',
    center: { lat: 21.5218, lng: 77.7812 },
    // top: true,
  },
};

export const locations = Object.keys(Location) as Location[];

export const activeLocations = locations.filter((loc) => locationsDetails[loc].active);
export const topLocations = locations.filter((loc) => locationsDetails[loc].top);

export const getLocationDetails = (value: Location) => locationsDetails[value];

export const getLocationName = (location: Location, locale?: Locale) => {
  const value = locationsDetails[location].name;

  if (typeof value === 'string') {
    return value;
  }

  return value[locale ?? 'en'];
};

export const getLocationCenter = (location: Location) => {
  return locationsDetails[location].center;
};

export const isLocation = (value: string): value is Location => {
  return locations.includes(value as Location);
};

export const getLocationsByCountry = (country: LocationCountry) => {
  return locations.filter((location) => locationsDetails[location].country === country);
};

import { ParamDef } from './search-params';

const DEFAULT_ROWS_PER_PAGE = 25;

export type PaginationParams = {
  page: number;
  rows: number;
};

export const paginationParams: { page: ParamDef<number>; rows: ParamDef<number> } = {
  page: {
    get: (value) => value ?? 1,
    set: (value) => (value === 1 ? undefined : value),

    encode: (value) => value.toString(),
    decode: (value) => {
      if (typeof value === 'string') {
        return parseInt(value, 10);
      }
    },
  },

  rows: {
    get: (value) => value ?? DEFAULT_ROWS_PER_PAGE,
    set: (value) => (value === DEFAULT_ROWS_PER_PAGE ? undefined : value),

    encode: (value) => value.toString(),
    decode: (value) => {
      if (typeof value === 'string') {
        return parseInt(value, 10);
      }
    },
  },
};

import { Locale } from '@koolumbus/shared/config';
import { PropertyFacilities } from '@prisma/client';

export type PropertyFacilitiesData = Omit<PropertyFacilities, 'propertyId'>;
export type FacilityKey = keyof PropertyFacilitiesData;

export const facilitiesObj: Record<FacilityKey, null> = {
  tv: null,
  dvd: null,
  gas: null,
  oven: null,
  safe: null,
  dryer: null,
  hiFi: null,
  stove: null,
  garage: null,
  juicer: null,
  porter: null,
  shower: null,
  stereo: null,
  towels: null,
  balcony: null,
  bathtub: null,
  heating: null,
  jacuzzi: null,
  laundry: null,
  parking: null,
  terrace: null,
  toaster: null,
  babyCot: null,
  elevator: null,
  bedLinen: null,
  cdPlayer: null,
  hairDryer: null,
  microwave: null,
  assistance: null,
  dishwasher: null,
  frontYard: null,
  noSmoking: null,
  condominium: null,
  electricity: null,
  coffeePress: null,
  petsAllowed: null,
  satelliteTv: null,
  waterKettle: null,
  swimmingPool: null,
  fridgeFreezer: null,
  privateGarden: null,
  vacuumCleaner: null,
  centralHeating: null,
  electricKettle: null,
  washingMachine: null,
  airConditioning: null,
  bedLinenTowels: null,
  espressoMachine: null,
  carParkingExtra: null,
  wirelessInternet: null,
  autonomousHeating: null,
  ironIroningBoard: null,
  highSpeedInternet: null,
  maintenanceService: null,
  kitchenUtensilsProvided: null,
  maidServiceUponRequest: null,
  concierge: null,
} as const;

export const facilitiesKeys = Object.keys(facilitiesObj) as FacilityKey[];
export const isFacilityKey = (key: string) => facilitiesKeys.includes(key as FacilityKey);

export const primaryFacilities: FacilityKey[] = [
  'wirelessInternet',
  'washingMachine',
  'airConditioning',
  'dishwasher',
  'heating',
  'balcony',
  'garage',
];

export const facilitiesLabels: Record<FacilityKey, Record<Locale, string>> = {
  wirelessInternet: { en: 'WiFi', it: 'WiFi' },
  airConditioning: { en: 'Air conditioning', it: 'Aria condizionata' },
  washingMachine: { en: 'Washing machine', it: 'Lavatrice' },
  satelliteTv: { en: 'Satellite TV', it: 'TV satellitare' },
  tv: { en: 'TV', it: 'TV' },
  bathtub: { en: 'Bathtub', it: 'Vasca da bagno' },
  terrace: { en: 'Terrace', it: 'Terrazza' },
  elevator: { en: 'Elevator', it: 'Ascensore' },
  maidServiceUponRequest: {
    en: 'Maid service upon request',
    it: 'Servizio di pulizia su richiesta',
  },
  microwave: { en: 'Microwave', it: 'Microonde' },
  noSmoking: { en: 'No smoking', it: 'Vietato fumare' },
  balcony: { en: 'Balcony', it: 'Balcone' },
  frontYard: { en: 'Front yard', it: 'Giardino' },
  espressoMachine: { en: 'Coffee machine', it: 'Macchina del caffè' },
  shower: { en: 'Shower', it: 'Doccia' },
  privateGarden: { en: 'Private garden', it: 'Giardino privato' },
  dryer: { en: 'Dryer', it: 'Asciugatrice' },
  highSpeedInternet: { en: 'High speed internet', it: 'Connessione internet ad alta velocità' },
  stereo: { en: 'Stereo', it: 'Stereo' },
  vacuumCleaner: { en: 'Vacuum cleaner', it: 'Aspirapolvere' },
  autonomousHeating: { en: 'Autonomous heating', it: 'Riscaldamento autonomo' },
  heating: { en: 'Heating', it: 'Riscaldamento' },
  carParkingExtra: { en: 'Car parking ( extra )', it: 'Parcheggio ( extra )' },
  parking: { en: 'Parking', it: 'Parcheggio' },
  safe: { en: 'Safe', it: 'Cassaforte' },
  ironIroningBoard: { en: 'Iron & ironing board', it: 'Ferro & tavolo da stiro' },
  toaster: { en: 'Toaster', it: 'Tostapane' },
  dishwasher: { en: 'Dishwasher', it: 'Lavapiatti' },
  petsAllowed: { en: 'Pets allowed', it: 'Animali domestici ammessi' },
  assistance: { en: 'Assistance 24/7', it: 'Assistenza 24/7' },
  swimmingPool: { en: 'Swimming pool', it: 'Piscina' },
  babyCot: { en: 'Baby cot', it: 'Culla' },
  juicer: { en: 'Juicer', it: 'Spremiagrumi' },
  bedLinenTowels: { en: 'Bed linen & towels', it: 'Biancheria da letto & asciugamani' },
  hairDryer: { en: 'Hair dryer', it: 'Asciugacapelli' },
  oven: { en: 'Oven', it: 'Forno' },
  fridgeFreezer: { en: 'Fridge-freezer', it: 'Frigo-freezer' },
  coffeePress: { en: 'Coffee press', it: 'Coffee press' },
  dvd: { en: 'Lettore DVD', it: 'DVD Player' },
  maintenanceService: { en: 'Maintenance service', it: 'Servizi di manutenzione' },
  electricKettle: { en: 'Electric kettle', it: 'Bollitore elettrico' },
  porter: { en: 'Porter', it: 'Facchino' },
  hiFi: { en: 'Hi-Fi', it: 'Hi-Fi' },
  laundry: { en: 'Laundry', it: 'Lavanderia' },
  stove: { en: 'Stove', it: 'Stufa' },
  gas: { en: 'Gas', it: 'Gas' },
  waterKettle: { en: 'Water kettle', it: 'Bollitore Acqua' },
  kitchenUtensilsProvided: { en: 'Kitchen utensils provided', it: 'Utensili da cucina forniti' },
  centralHeating: { en: 'Central heating', it: 'Riscaldamento centralizzato' },
  towels: { en: 'Towels', it: 'Asciugamani' },
  bedLinen: { en: 'Bed linen', it: 'Biancheria da letto' },
  jacuzzi: { en: 'Jacuzzi', it: 'Jacuzzi' },
  cdPlayer: { en: 'CD Player', it: 'Riproduttore CD' },
  electricity: { en: 'Electricity', it: 'Elettricità' },
  condominium: { en: 'Condominium', it: 'Condominio' },
  garage: { en: 'Garage', it: 'Garage' },
  concierge: { en: 'Concierge', it: 'Portineria' },
};

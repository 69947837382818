import { Locale } from '@koolumbus/shared/config';
import { date } from '@koolumbus/shared/utils';

export const formatDate = (value: Date, locale: Locale, format = 'D MMM, YY') => {
  return date(value).locale(locale).format(format);
};

export const isDateString = (value: string) =>
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/.test(value);

export default date;

import type { WebENV } from '@koolumbus/shared/config';

interface Config {
  env: WebENV;
  gcloudProjectId: string;
  mapsApiKey: string;
  mapboxToken: string;
}

const getEnv = (): WebENV => {
  // USING KOOLUMBUS_ENV, NOT NODE_ENV !
  const env = process.env.NEXT_PUBLIC_KOOLUMBUS_ENV;

  if (!['development', 'staging', 'production'].includes(env ?? '')) {
    if (process.env.NODE_ENV !== 'test') {
      console.warn('KOOLUMBUS_ENV is not a valid value, setting to: development');
    }
    return 'development';
  }

  return env as WebENV;
};

const configs: { [key in WebENV]: Config } = {
  development: {
    env: 'development',
    gcloudProjectId: 'pms-new',
    mapsApiKey: 'AIzaSyDZYMiM-vGKvLQCs3G3_V_cY8W0BAE1fdA',
    mapboxToken:
      'pk.eyJ1IjoiZGFyaW9pZWxhcmRpIiwiYSI6ImNrY2dvZmp1ZjA1ODQycW8zaXJlYWQwZzYifQ.oyhcIziL4tuHRHRN55I9Ng',
  },
  staging: {
    env: 'staging',
    gcloudProjectId: 'pms-stage',
    mapsApiKey: 'AIzaSyAi70el4MwYOgDqLaizzwn6Sb0-_R30wkY',
    mapboxToken:
      'pk.eyJ1IjoiZGFyaW9pZWxhcmRpIiwiYSI6ImNrY2dvZmp1ZjA1ODQycW8zaXJlYWQwZzYifQ.oyhcIziL4tuHRHRN55I9Ng',
  },
  production: {
    env: 'production',
    gcloudProjectId: 'pms-prod-271508',
    mapsApiKey: 'AIzaSyBJWvDg7ZRYdvN2WG8ix8LwukqPhLVevPY',
    mapboxToken:
      'pk.eyJ1IjoiZGFyaW9pZWxhcmRpIiwiYSI6ImNrY2dvZmp1ZjA1ODQycW8zaXJlYWQwZzYifQ.oyhcIziL4tuHRHRN55I9Ng',
  },
};

export const config = configs[getEnv()];

export const IS_DEV = config.env === 'development';
export const IS_STAGE = config.env === 'staging';
export const IS_PROD = config.env === 'production';

import { date } from './date';

export type StayType = 'short' | 'medium';

export const getStayLength = (dateFrom: Date, dateTo: Date): number => {
  return date(dateTo).diff(dateFrom, 'd');
};

export const getStayType = (dateFrom: Date, dateTo: Date): StayType => {
  return getStayLength(dateFrom, dateTo) > 29 ? 'medium' : 'short';
};

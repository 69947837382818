import { Locale } from '@koolumbus/shared/config';

export const formatBedrooms = (value: number, locale: Locale) => {
  switch (locale) {
    case 'it':
      return `${value} ${value === 1 ? 'camera' : 'camere'}`;
    case 'en':
    default:
      return `${value} ${value === 1 ? 'bedroom' : 'bedrooms'}`;
  }
};

export const formatRestrooms = (value: number, locale: Locale) => {
  switch (locale) {
    case 'it':
      return `${value} ${value === 1 ? 'bagno' : 'bagni'}`;
    case 'en':
    default:
      return `${value} ${value === 1 ? 'restroom' : 'restrooms'}`;
  }
};

export const formatRooms = (bedrooms: number, restrooms: number, locale: Locale) => {
  return `${formatBedrooms(bedrooms, locale)}${
    restrooms > 0 ? `, ${formatRestrooms(restrooms, locale)}` : ''
  }`;
};

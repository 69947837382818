import { Locale } from '@koolumbus/shared/config';
import dayjs from 'dayjs';
import arraySupport from 'dayjs/plugin/arraySupport';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(arraySupport);

export const date = dayjs.utc;

export function yearStart() {
  return new Date(date().year(), 0, 1);
}

export function pastYearStart() {
  return new Date(date().year() - 1, 0, 1);
}

export function monthStart() {
  return new Date(date().year(), date().month(), 1);
}

export function pastMonthStart() {
  return new Date(date().year() - 1, date().month(), 1);
}

export function lastYearToday() {
  return new Date(date().year() - 1, date().month(), date().date());
}

export function lastYearNow() {
  return date().subtract(1, 'year').toDate();
}

export function oneYearFromNow() {
  return date().subtract(1, 'year').toDate();
}

export function pastOneYearFromNow() {
  return date(oneYearFromNow()).subtract(1, 'year').toDate();
}

export function oneMonthFromNow() {
  return date().subtract(1, 'month').toDate();
}

export function pastOneMonthFromNow() {
  return date(oneMonthFromNow()).subtract(1, 'year').toDate();
}

export function todayStart() {
  return new Date(date().year(), date().month(), date().date());
}

/** convert a localized date to a utc date with same year, month, day and at midnight */
export function getUTCDate(value: null): null;
export function getUTCDate(value: Date): Date;
export function getUTCDate(value: Date | null) {
  if (value === null) {
    return null;
  }

  return date([value.getFullYear(), value.getMonth(), value.getDate()]).toDate();
}

export const formatDate = (
  value: Date,
  locale: Locale,
  options?: { format?: string; utc?: boolean },
) => {
  const { format, utc } = { format: 'D MMM, YY', utc: true, ...options };

  if (!utc) {
    return dayjs(value).locale(locale).format(format);
  }

  return date(value).locale(locale).format(format);
};

export const dateFromMinutes = (mins: number): Date => {
  const hours = Math.floor(mins / 60);
  const d = date()
    .hour(hours)
    .minute(mins - hours * 60);

  return d.toDate();
};

export const formatTime = (minutes: number): string => {
  return date(dateFromMinutes(minutes)).format('HH:mm');
};

import { WebENV } from '@koolumbus/shared/config';
import { getStorageBucketPrefix } from './storage';

export function getPropertyImageURL(env: WebENV, tenantId: string, id: string) {
  const storageBucketPrefix = getStorageBucketPrefix(env);
  return `https://storage.googleapis.com/${storageBucketPrefix}_properties-images/${tenantId}/${id}.jpg`;
}

export function getTenantLogoUrl(env: WebENV, tenantId: string) {
  const storageBucketPrefix = getStorageBucketPrefix(env);
  return `https://storage.googleapis.com/${storageBucketPrefix}_tenants-logos/${tenantId}.jpg`;
}

export const MIN_AUCTION_PRICE_FROM = 100;
export const MAX_AUCTION_PRICE_TO = 3000;

export const formatBudget = (priceFrom: number | null, priceTo: number | null): string => {
  const from = priceFrom ?? MIN_AUCTION_PRICE_FROM;
  const to = priceTo ?? MAX_AUCTION_PRICE_TO;
  const hasPlus = to === MAX_AUCTION_PRICE_TO;

  return `${from?.toLocaleString()} - ${to?.toLocaleString()}${hasPlus ? '+' : ''}`;
};

import { isLocale, Locale } from '@koolumbus/shared/config';
import 'dayjs/locale/en';
import 'dayjs/locale/it';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export type LocalizedValue<T> = { [key in Locale]: T };

export const useLocale = (): Locale => {
  const router = useRouter();
  return router.locale && isLocale(router.locale) ? router.locale : 'en';
};

export const useIntl = () => {
  const router = useRouter();
  const locale = useLocale();

  useEffect(() => {
    // document.cookie = `firebase-language-override=${locale}`;
    document.cookie = `NEXT_LOCALE=${locale}`;
  }, [locale]);

  const setLocale = (value: Locale) => {
    return router.replace(router.pathname, router.asPath, { locale: value });
  };

  return {
    locale,
    setLocale,
  };
};

export type Txs<
  BaseDef extends Record<string, unknown>,
  Def = { [key in keyof BaseDef]: BaseDef[key] },
> = {
  en: BaseDef;
} & LocalizedValue<Def>;

export const useTxs = <T extends Record<string, unknown>>(txs: Txs<T>): T => {
  const locale = useLocale();
  return txs[locale];
};

export const createTxs = <T extends Record<string, unknown>>(def: Txs<T>) => {
  return () => {
    return useTxs(def);
  };
};

import { config } from '@koolumbus/web/config';
import type { AppProps as NextAppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import 'react-image-lightbox/style.css';
import 'react-multi-carousel/lib/styles.css';
import 'tippy.js/dist/tippy.css';
import { AuthProvider } from '../lib/context/auth.context';
import { useSearchStateStorage } from '../lib/state/search-state.store';
import { trpc } from '../lib/trpc';
import { PageWithLayout } from '../lib/types/page';
import '../style/nprogress.css';
import '../style/react-day-picker.css';
import '../style/styles.css';

NProgress.configure({ trickleSpeed: 150, showSpinner: false });

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

type AppProps = NextAppProps & {
  Component: PageWithLayout;
};

function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    console.log(
      `KOOLUMBUS STARTED - NODE_ENV: ${process.env.NODE_ENV} - KOOLUMBUS_ENV: ${config.env}`,
    );
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <Providers>
      <>{getLayout(<Component {...pageProps} />)}</>
    </Providers>
  );
}

const Providers: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  useSearchStateStorage();

  return (
    <>
      <AuthProvider>{children}</AuthProvider>
      <Toaster />
    </>
  );
};

export default trpc.withTRPC(App);
